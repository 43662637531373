export default {
	routes: {
		public: [
			{ path: "/login", name: "Login", exact: true },
			{ path: "/forgot-password", name: "ForgotPassword", exact: true },
			{
				name: "ResetPassword",
				path: "/reset-password",
				exact: true,
			},
		],
		private: [
			{
				path: "/dashboard",
				name: "Dashboard",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/individual-customers",
				name: "Individual Customers Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/individual-customers/detail/:id",
				name: "Individual Customer Detail ",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/individual-customers/edit/:id",
				name: "Individual Customer Edit ",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/corporate-customers",
				name: "Corporate Customers Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/corporate-customers/detail/:id",
				name: "Corporate Customer Detail ",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/corporate-customers/edit/:id",
				name: "Corporate Customer Edit ",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/companies",
				name: "Companies Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/companies/add",
				name: "Update Company",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/companies/edit/:id",
				name: "Update Company",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/customers-management/companies/detail/:id",
				name: "Company Detail",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/cpos",
				name: "CPO Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/cpos/detail/:id",
				name: "CPO Detail",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/cpos/edit/:id",
				name: "Edit CPO",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/merchants",
				name: "Merchants Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/merchants/edit/:id",
				name: "Edit Merchant",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/merchants/add",
				name: "Add Merchant",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/locations",
				name: "Location Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/locations/add",
				name: "Update Location",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/locations/detail/:id",
				name: "Location Detail",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/locations/edit/:id",
				name: "Update Location",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/evses",
				name: "EVSE Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/evses/add",
				name: "Update EVSE",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/chargers-management/evses/edit/:id",
				name: "Update EVSE",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/session-management/dashboard",
				name: "Session Dashboard",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/session-management/all",
				name: "All Session Mangement",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/session-management/detail/:id",
				name: "Session Detail",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/promotion-management/promo-codes",
				name: "Promocode Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/promotion-management/promo-codes/add",
				name: "Update Promo Code",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/promotion-management/promo-codes/edit/:id",
				name: "Update Promo Code",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/promotion-management/onboarding-campaigns",
				name: "Onboarding Campaign Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/promotion-management/onboarding-campaigns/add",
				name: "Update Campaign",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/promotion-management/onboarding-campaigns/edit/:id",
				name: "Update Campaign",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/reports-management/",
				name: "Report Management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/master-setting/vehicle-information",
				name: "Vehicle Information",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/master-setting/penalty-threshold",
				name: "Penalty Threshold",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/master-setting/referral",
				name: "Referral",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/master-setting/minimal-charge",
				name: "Minimal Charge Listing",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin-management/cms-accounts",
				name: "CMS Account",
				exact: true,
				config: {
					policies: [["*"], ["*", "*"]],
				},
			},
			{
				path: "/admin-management/cms-accounts/add",
				// name: "Add CMS Account",
				name: "Update CMS Account",
				exact: true,
				config: {
					policies: [["*"], ["*", "*"]],
				},
			},
			{
				path: "/admin-management/cms-accounts/edit/:id",
				name: "Update CMS Account",
				exact: true,
				config: {
					policies: [["*"], ["*", "*"]],
				},
			},
			{
				path: "/admin-management/audit-trails",
				name: "Audit Trails",
				exact: true,
				config: {
					policies: [["*"], ["*", "*"]],
				},
			},
			{
				path: "/admin/change-password",
				name: "Change Password",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin/master-data",
				name: "Master Data",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Edit Profile",
				path: "/account-management/edit/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Cms Account Management",
				path: "/admin/cms-accounts",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "My Account",
				path: "/admin/my-account",
				exact: true,
				config: {
					policies: [["*"], ["*"]],
				},
			},
			{
				name: "Create Cms Account Management",
				path: "/admin/cms-accounts/create",
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},

			{
				name: "Edit Cms Account Management",
				path: "/admin/cms-accounts/edit/:id",
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
		],
	},
};
