import moment from "moment";
import { isArray } from "lodash";
import APIResponseDto from "../dtos/api.dto";
import { apiResolver } from "../services/helpers";
import OptionDto from "../dtos/selectOption.dto";

//check csv ext for fromik validation
export const isCsvExtension = (value) => {
	const name = value.name;
	const lastDot = name.lastIndexOf(".");
	const extension = name.substring(lastDot + 1);
	return extension === "csv";
};

export const isSidebarOpened = () => {
	const rule1 = document.body.classList
		.toString()
		.split(" ")
		.filter((c) => c === "sidebar-lg-show").length;

	const rule2 = document.body.classList
		.toString()
		.split(" ")
		.filter((c) => c === "sidebar-show").length;

	return rule1 + rule2 > 0;
};

/**
 * Check the type of the error and return error in string
 *
 * @param err
 * @returns string
 */
export const extractErrorMsg = (err: any) => {
	if (typeof err === "string") {
		return err;
	}
	if (err?.message && isArray(err.message)) {
		return err.message.join("\n");
	}
	return err.message;
};

export const formatDateTime = (date: Date) =>
	date ? moment(date).format("DD/MM/YYYY HH:mm") : "";

export const formatDateRange = (date: any) => {
	const [from, to] = date.split("~").map((x: string) => x.trim());

	return `${from ? moment(from).format("YYYY-MM-DD") : ""},${
		to ? moment(to).format("YYYY-MM-DD") : ""
	}`;
};

export function dataURLtoFile(dataurl: any, filename: any) {
	const arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]);

	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
}

export function downloadFile(data: any, filename: any) {
	const url = window.URL.createObjectURL(data.response);
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
}

export const actionDetail = {
	loading: false,
	error: "",
	success: false,
};

export const defaultRequestParams = {
	Page: 1,
	Size: 20,
};

/**
 * structure data from api to table props
 * return fallback values to table if data was null
 *
 * @param data
 */
export const decorateTableProps = (data: any) => {
	if (!data) {
		return {
			data: [],
			pagination: {
				pageSize: 20,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	}

	return {
		data: data.data.dataList,
		pagination: {
			pageSize: data.data.pageSize,
			totalItems: data.data.totalCount,
			totalPages: data.data.totalPage,
			currentPage: data.data.currentPage,
		},
	};
};

export const getTime = (date: any, time: any): string => {
	let dateStr = moment(date).format("YYYY-MM-DD");
	const formattedTime = moment(time).format("HH:mm");
	//
	return new Date(`${dateStr}T${formattedTime}`).toISOString();
};

export const transformToSelectOptions = (
	data: Array<any>,
	valueIndex: string = "id",
	labelIndex: string = "name"
): Array<OptionDto> => {
	return data.map((item) => ({
		value: item[valueIndex],
		label: item[labelIndex],
	}));
};

/**
 * get left and top offset of a specific html element
 *
 * @param HTMLELEMENT
 * @returns @Object { top: number, left: number }
 */
export const getOffset = (el: any) => {
	var _x = 0;
	var _y = 0;
	while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
};

// Generate Timeline
export const generateMomentTimeline = ({
	interval, // moment duration object
	from, // moment duration object
	to, // moment duration object
	unit, // moment duration unit
}) => {
	const durationFrom = moment.duration(from, unit);
	const durationTo = moment.duration(to, unit);

	const values: any = [];

	const getTimelines = (current: any = durationFrom.clone()) => {
		values.push(current);

		const next = current.clone().add(interval);

		if (next.asMilliseconds() < durationTo.asMilliseconds()) {
			getTimelines(next);
		}
	};

	getTimelines();

	return values;
};

export const arrayRepeat = (arr, times) => {
	return new Array(times).fill(arr);
};

/**
 * Helper to render nodes
 *
 * @param value
 * @returns
 */
export const renderJSXNode = (value) => {
	return typeof value === "function" ? value() : value;
};

// Get element's CSS property value
export const getElementStyle = (el, styleProp) => {
	var value,
		defaultView = (el.ownerDocument || document).defaultView;
	// W3C standard way:
	if (defaultView && defaultView.getComputedStyle) {
		// sanitize property name to css notation
		// (hypen separated words eg. font-Size)
		styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
		return defaultView
			.getComputedStyle(el, null)
			.getPropertyValue(styleProp);
	} else if (el.currentStyle) {
		// IE
		// sanitize property name to camelCase
		styleProp = styleProp.replace(/\-(\w)/g, function (str, letter) {
			return letter.toUpperCase();
		});
		value = el.currentStyle[styleProp];
		// convert other units to pixels on IE
		if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
			return (function (value) {
				var oldLeft = el.style.left,
					oldRsLeft = el.runtimeStyle.left;
				el.runtimeStyle.left = el.currentStyle.left;
				el.style.left = value || 0;
				value = el.style.pixelLeft + "px";
				el.style.left = oldLeft;
				el.runtimeStyle.left = oldRsLeft;
				return value;
			})(value);
		}
		return value;
	}
};

/**
 * Get strips tags
 * @param html
 * @returns string
 */
export const stripTags = (html: any) => {
	return html.replace(/<[^>]*>?/gm, "");
};

export const renderDataForPieChart = (data, title): any => {
	const backgroundColours = ["#4285F4", "#ffbb33", "#45caf", "#FF5252"];
	const hoverColors = ["#6ea0f2", "#fec451", "#78daff", "#fa6e6e"];
	let labels: string[] = [];
	let values: number[] = [];
	let backgroundColourArray: string[] = [];
	let hoverColorArray: string[] = [];
	data.forEach((item, index) => {
		labels.push(item.connectorType);
		values.push(item.totalMinutes.toFixed(3));
		backgroundColourArray.push(backgroundColours[index]);
		hoverColorArray.push(hoverColors[index]);
	});
	return {
		labels: labels,
		datasets: [
			{
				label: title,
				data: values,
				backgroundColor: backgroundColourArray,
				hoverBackgroundColor: hoverColorArray,
				hoverOffset: 5,
			},
		],
	};
};
