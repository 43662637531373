import { lazy } from "react";

export const Login = lazy(() => import("./Login/Login"));

export const ForgotPassword = lazy(() =>
	import("./ForgotPassword/ForgotPassword")
);

export const ResetPassword = lazy(() =>
	import("./ResetPassword/ResetPassword")
);

export const Dashboard = lazy(() => import("./Dashboard/Dashboard"));

export const ChangePassword = lazy(() =>
	import(
		"./AdminManagement/ChangePasswordFormBuilder/ChangePasswordFormBuilder"
	)
);

export const MyAccount = lazy(() =>
	import("./AccountManagement/MyAccount/MyAccount")
);

export const MasterData = lazy(() =>
	import("./AdminManagement/MasterDataBuilder/MasterDataBuilder")
);

export const CmsAccountManagement = lazy(() =>
	import("./CMSAccountManagement/CMSAccountManagement")
);

export const CreateCmsAccountManagement = lazy(() =>
	import("./CMSAccountManagement/CreateCMSAccountManagement")
);

export const EditCmsAccountManagement = lazy(() =>
	import("./CMSAccountManagement/EditCMSAccountManagement")
);

//Customers

export const IndividualCustomersListing = lazy(() =>
	import(
		"./CustomerManagement/IndividualCustomers/IndividualListing/IndividualListing"
	)
);
export const IndividualCustomerDetail = lazy(() =>
	import(
		"./CustomerManagement/IndividualCustomers/IndividualDetail/IndividualDetail"
	)
);
export const IndividualCustomerEdit = lazy(() =>
	import(
		"./CustomerManagement/IndividualCustomers/EditIndividual/EditIndividual"
	)
);

export const CorporateCustomersListing = lazy(() =>
	import(
		"./CustomerManagement/CorporateCustomers/CorporateListing/CorporateListing"
	)
);

export const CorporateCustomerDetail = lazy(() =>
	import(
		"./CustomerManagement/CorporateCustomers/CoporateDetail/CorporateDetail"
	)
);
export const CorporateCustomerEdit = lazy(() =>
	import(
		"./CustomerManagement/CorporateCustomers/EditCorporate/EditCorporate"
	)
);

export const CompaniesListing = lazy(() =>
	import("./CustomerManagement/Companies/CompanyListing/CompanyListing")
);

export const CompanyDetail = lazy(() =>
	import("./CustomerManagement/Companies/CompanyDetail/CompanyDetail")
);

export const UpdateCompany = lazy(() =>
	import("./CustomerManagement/Companies/Update Company/UpdateCompany")
);

//chargers

export const CPOListing = lazy(() =>
	import("./ChargersManagement/CPOs/CPOListing/CPOListing")
);
export const CPODetail = lazy(() =>
	import("./ChargersManagement/CPOs/CPODetails/CPODetail")
);
export const EditCPO = lazy(() =>
	import("./ChargersManagement/CPOs/EditCPO/EditCPO")
);

export const MerchantsListing = lazy(() =>
	import("./ChargersManagement/Merchants/MerchantsListing")
);

export const AddMerchant = lazy(() =>
	import("./ChargersManagement/Merchants/UpdateMerchants")
);

export const EditMerchant = lazy(() =>
	import("./ChargersManagement/Merchants/UpdateMerchants")
);

export const MinimalChargeListing = lazy(() =>
	import("./MasterSettingManagement/MinimalCharge/MinimalChargeListing")
);

export const LocationListing = lazy(() =>
	import("./ChargersManagement/Locations/LocationListing/LocationListing")
);
export const LocationDetail = lazy(() =>
	import("./ChargersManagement/Locations/LocationDetail/LocationDetail")
);
export const UpdateLocation = lazy(() =>
	import("./ChargersManagement/Locations/UpdateLocation/UpdateLocation")
);

export const EVSEListing = lazy(() =>
	import("./ChargersManagement/EVSEs/EVSEListing/EVSEListing")
);
export const UpdateEVSE = lazy(() =>
	import("./ChargersManagement/EVSEs/UpdateEVSE/UpdateEVSE")
);

//session Management
export const SessionDashboard = lazy(() =>
	import("./SessionManagement/SessionDashboard/SessionDashboard")
);
export const AllSessionMangement = lazy(() =>
	import("./SessionManagement/AllSessionManagement/AllSessionManagement")
);
export const SessionDetail = lazy(() =>
	import("./SessionManagement/SessionDetail/SessionDetail")
);

//Promotions management
export const PromocodeListing = lazy(() =>
	import("./PromocodeManagement/Promocodes/PromocodeListing/PromocodeListing")
);

export const UpdatePromoCode = lazy(() =>
	import("./PromocodeManagement/Promocodes/UpdatePromoCode/UpdatePromoCode")
);

export const OnboardingCampaignListing = lazy(() =>
	import(
		"./PromocodeManagement/OnboardingCampaigns/OnboardingCampaignListing/OnboardingCampaignListing"
	)
);

export const UpdateCampaign = lazy(() =>
	import(
		"./PromocodeManagement/OnboardingCampaigns/UpdateCampaign/UpdateCampaign"
	)
);

//Admin Management

export const CMSAccount = lazy(() =>
	import("./AdminManagement/CMSAccounts/CMSAccountListing/CMSAccountListing")
);
export const UpdateCMSAccount = lazy(() =>
	import("./AdminManagement/CMSAccounts/UpdateCMSAccount/UpdateCMSAccount")
);

export const AuditTrails = lazy(() =>
	import("./AdminManagement/AuditTrails/AuditTrails")
);

//Master Setting
export const VehicleInformation = lazy(() =>
	import(
		"./MasterSettingManagement/VehicleInformation/VehicleInformationListing/VehicleInformationListing"
	)
);

export const PenaltyThreshold = lazy(() =>
	import("./MasterSettingManagement/PenaltyThreshold/PenaltyThreshold")
);
export const Referral = lazy(() =>
	import("./MasterSettingManagement/Referral/Referral")
);

//Reports
export const ReportManagement = lazy(() =>
	import("./ReportManagement/GenerateReport")
);
