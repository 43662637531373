import React from "react";
import axios from "axios";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";

import configureStore from "./store";

import "./App.scss";
import MainRouter from "./containers/Nav/MainRouter";
import { QueryClientProvider, QueryClient } from "react-query";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const store = configureStore();
export const persistor = persistStore(store);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// disable retries
			retry: false,
			// disable stale data
			refetchOnWindowFocus: false,
		},
	},
});
const App: React.FC = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<QueryClientProvider client={queryClient}>
				<MainRouter />
			</QueryClientProvider>
		</PersistGate>
	</Provider>
);

export default App;
