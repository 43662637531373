import { getMasterEnum } from './../services/masterEnum/master-enum.service';
import { createAsyncAction } from "typesafe-actions";

export const masterEnumAsyncAction: any = createAsyncAction(
    "MASTER_ENUM_REQUEST",
    "MASTER_ENUM_SUCCESS",
    "MASTER_ENUM_FAILURE"
  )<any, any, Error>();
  
  export const dispatchGetMasterEnum = (): any => (
    dispatch: any
  ): any => {
    dispatch(masterEnumAsyncAction.request());
  
    getMasterEnum()
      .then((res: any) => {
        if (res.data.code !== 0) {
          dispatch(masterEnumAsyncAction.failure(res.data.error_msg));
        } else {
          dispatch(masterEnumAsyncAction.success(res.data.data));
        }
      })
      .catch((error: any) => {
        dispatch(masterEnumAsyncAction.failure(error));
      });
  };