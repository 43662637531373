import UserDto from "../dtos/user.dto";

/**
 * This will trigger in routing before the view was seen.
 * Check the user had specific policy to view the route.
 *
 * @param policies
 * @param user
 * @returns boolean
 */
const hasAccess = (policies?: Array<string[]>, user?: UserDto): Boolean => {
	if (!policies) {
		return true;
	}

	const [roles, permissions] = policies;

	const hasRole: Boolean =
		roles.includes("*") ||
		roles.some((role) => role === String(user?.role).toLowerCase());

	// TODO: there is no specific logic for Read or Write permission in this version.
	// once user had the authorized roles, he can read or write
	const hasPermissions: Boolean =
		permissions.includes("*") ||
		permissions.every((pm) => user?.permissions?.includes(pm));

	return hasRole;
};

export default hasAccess;
