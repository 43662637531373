// import { any } from "prop-types";
// import { LoadingIndicator } from "react-select/src/components/indicators";
// import { dispatch } from "rxjs/internal/observable/pairs";
import { createAsyncAction } from "typesafe-actions";
import { LoginDto } from "../dtos/auth.dto";
import { doLogin } from "../services/auth/auth.service";

// create three kinds of asynchronous actions.request, failure and success
export const authLoginAsyncAction: any = createAsyncAction(
	"AUTH_LOGIN_REQUEST",
	"AUTH_LOGIN_SUCCESS",
	"AUTH_LOGIN_FAILURE"
)<LoginDto, any, Error>();

export const forgetPasswordAsyncAction: any = createAsyncAction(
	"FORGET_PASSWORD_REQUEST",
	"FORGET_PASSWORD_SUCCESS",
	"FORGET_PASSWORD_FAILURE"
)<any, any, Error>();

export const authLogoutAction: any = createAsyncAction(
	"AUTH_LOGOUT_REQUEST",
	"AUTH_LOGOUT_SUCCESS",
	"AUTH_LOGOUT_FAILURE"
)<any, any, Error>();

export const dispatchLogin = (payload: LoginDto): any => (
	dispatch: any
): any => {
	dispatch(authLoginAsyncAction.request());

	doLogin(payload)
		.then((res: any) => {
			if (res.data.code !== 0) {
				dispatch(authLoginAsyncAction.failure(res.data.message));
			} else {
				dispatch(authLoginAsyncAction.success(res.data.data));
			}
		})
		.catch((error: any) => {
			dispatch(authLoginAsyncAction.failure(error));
		});
};

export const dispatchLogout = () => (dispatch: any) =>
	dispatch(authLogoutAction.request());

// Create logout action
