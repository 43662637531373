import axios from "axios";
import { isEmpty } from "lodash";
import { stringify } from "qs";
import APIResponseDto, { APIRequestDto } from "../dtos/api.dto";

const API_URL = process.env.REACT_APP_API_URL;
const needBasicAuth = process.env.REACT_APP_BASIC_AUTH;

/**
 * A wrapper function to decorate API call with axios
 *
 * @param path 				API url
 * @param method 			HTTP method
 * @param payload 			Payload
 * @param params 			Request params
 * @param token 			Bearer token
 * @param header 			HTTP header
 * @param responseType 		specific response type
 * @returns promise
 */
export const api = (
	path: string,
	method = "GET",
	payload: any,
	params?: any,
	token = "",
	header = "json",
	responseType = ""
) => {
	const options: any = {
		url: API_URL + path,
		method: method.toUpperCase(),
		headers: {},
	};

	if (!isEmpty(params)) {
		options["url"] =
			options["url"] + stringify(params, { addQueryPrefix: true });
	}

	if (token) {
		options["headers"]["Authorization"] = "Bearer " + token;
	}

	if (!token && needBasicAuth) {
		options["auth"] = {
			username: process.env.REACT_APP_AUTH_USER_NAME,
			password: process.env.REACT_APP_AUTH_PASSWORD,
		};
	}

	//this will work with all kind of payload
	if (!isEmpty(payload) || !!payload?.entries()) {
		options["data"] = payload;
	}

	if (responseType) {
		options["responseType"] = responseType;
	}

	switch (header) {
		// if multipart ignore the option headers
		case "multipart":
			options["headers"]["Content-Type"] = "multipart/form-data";
			break;
		case "csv":
			options["headers"]["Accept"] = "application/csv";
			options["responseType"] = "blob";
			break;
		default:
			options["headers"]["Content-Type"] = "application/json";
	}

	return axios(options);
};

/**
 * check error code from api result which was resolved after the react query mutation
 * if error code is not zero, throw with error message from api
 *
 * @param response
 * @param cb
 */
export function apiResolver(response: any, cb = (response: any) => null) {
	if (response.code !== 0) {
		// TODO: show only something went wrong exception if the environment is for the production;
		if (response.error_msg) return Promise.reject(response.error_msg);
		else return Promise.reject(response.message);
	} else {
		return cb(response);
	}
}

/**
 * Extract actual data from the api
 * @param data
 */
export const apiSelector = (data: any) =>
	apiResolver(data, (response: any) => response);
