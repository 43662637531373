import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { dispatchLogout } from "../actions/authAction";
import AuthReducerDto from "../dtos/auth-reducer.dto";
import client from "../utils/api-client";

/**
 * react hook for handling api client and
 * allow logout false will prevent from dispatching logout action in some cases(change password scenario which would keep the error on the component)
 *
 * @param allowLogout
 * @returns
 */
export function useClient(allowLogout = true) {
	const { token }: AuthReducerDto = useSelector(({ auth }: any) => auth);
	const dispatch = useDispatch();
	const handleLogout = () => {
		if (allowLogout) {
			dispatch(dispatchLogout());
		}
	};
	return React.useCallback(
		(endpoint: string, config = {}) =>
			client(endpoint, { ...config, token, forceLogout: handleLogout }),
		[token]
	);
}
