import React, { lazy, Suspense } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";

import { PrivateRoutes, PublicRoutes } from "./Routes";

import Loader from "../../components/Loader/Loader";

// Containers
const DefaultLayout = lazy(() => import("../DefaultLayout/DefaultLayout"));

const MainRouter: React.FC = () => {
	const { authenticated, profile } =
		useSelector((state: RootStateOrAny) => state.auth) || {};

	return (
		<BrowserRouter>
			<Suspense fallback={<Loader />}>
				{authenticated ? (
					<DefaultLayout>
						<PrivateRoutes
							authenticated={authenticated}
							user={profile}
						/>
					</DefaultLayout>
				) : (
					<Switch>
						<PublicRoutes authenticated={authenticated} />
					</Switch>
				)}
			</Suspense>
		</BrowserRouter>
	);
};

export default MainRouter;
