import AxiosClientDto from "../dtos/axios-client.dto";
import { api } from "../services/helpers";

const apiURL = process.env.REACT;

/**
 * a helper function for react query which will decorate api calls depends on the type of the parameters
 *
 * @param endpoint
 * @param param1
 * @return promise
 */
async function client(
	endpoint: string,
	{
		method = "GET",
		params,
		data,
		token,
		header = "json",
		forceLogout = () => null,
		onError = (error: any) => null,
		onSuccess = (response: any) => null,
		responseType,
	}: AxiosClientDto
) {
	return await api(
		endpoint,
		method,
		data,
		params,
		token,
		header,
		responseType
	)
		.then((response) => {
			onSuccess(response);
			return Promise.resolve(response);
		})
		.catch((error) => {
			onError(error);
			if (error.response.status === 401) {
				forceLogout();
				return Promise.reject({ message: "Unauthorized!" });
			}
			return Promise.reject(error);
		});
}

export default client;
