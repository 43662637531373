import React from "react";

const Loader: React.FC<{ text?: string; withoutText?: boolean }> = ({
	text = "Loading...",
	withoutText = false,
}) => {
	return (
		<div className="d-flex align-items-center w-100 text-center flex-column text-black-50">
			<div className="spinner-border " role="status" aria-hidden="true" />
			{!withoutText && (
				<div className="mt-2">
					<strong>{text}</strong>
				</div>
			)}
		</div>
	);
};

export default Loader;
