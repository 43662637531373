import {
	ChangePasswordDto,
	LoginDto,
	ResetPasswordDto,
} from "../../dtos/auth.dto";
import { api, apiResolver } from "../helpers";

import { useMutation } from "react-query";
import { useClient } from "../useClient";

export const doLogin = (payload: LoginDto): any =>
	api("/auth/login", "POST", payload);

/**
 *
 */
export const useForgotPassword = (options: any) => {
	const client = useClient();

	return useMutation(
		(email: string) =>
			client(`/auth/forgotPassword?email=${email}`, {
				method: "POST",
			}),
		{
			onSuccess: ({ data }) => apiResolver(data),
			...options,
		}
	);
};

export const useResetPassword = (options: any) => {
	const client = useClient();

	return useMutation(
		(body: ResetPasswordDto) =>
			client(`/auth/resetPassword`, {
				method: "POST",
				data: body,
			}),
		{
			onSuccess: ({ data }) => apiResolver(data),
			...options,
		}
	);
};

export const useChangePassword = (options: any) => {
	const client = useClient(false);

	return useMutation(
		(body: ChangePasswordDto) =>
			client("/profile/changePassword", {
				method: "POST",
				data: body,
			}),
		{
			onSuccess: ({ data }) => apiResolver(data),
			...options,
		}
	);
};
