import { createReducer } from "typesafe-actions";
import { REHYDRATE, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionDetail } from "../utils/misc";
import { masterEnumAsyncAction } from "../actions/masterEnumAction";

const initialState = {
	evseStatus: {},
	powerType: {},
	connectorType: {},
	cmsUserStatus: {},
	pricingType: {},
	contactPersonRole: {},
	billingFrequency: {},
	action: actionDetail,
};

const masterEnumReducer: any = persistReducer(
	{
		key: "masterEnum",
		storage,
		whitelist: [
			"evseStatus",
			"powerType",
			"connectorType",
			"cmsUserStatus",
			"pricingType",
			"contactPersonRole",
			"billingFrequency",
		],
	},

	createReducer(initialState)
		.handleAction(
			masterEnumAsyncAction.success,
			(state: any, { payload }: any) => ({
				...state,
				evseStatus: payload.evse_status,
                powerType : payload.power_type,
                connectorType : payload.connector_type,
                cmsUserStatus : payload.cms_user_status,
                pricingType : payload.pricing_type,
                contactPersonRole : payload.contact_person_role,
                billingFrequency : payload.billing_frequency
			})
		)

	/*
      [
        authLogoutAction,
        (state: any) => ({
          ...state,
          token: "",
          profile: {},
          authenticated: false,
        }),
      ],
    ]),
    initialState
  )*/
);

export default masterEnumReducer;
